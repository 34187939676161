/*
 * @Author: yuchengpeng yuchengpeng.ex
 * @Date: 2022-11-03 15:04:58
 * @LastEditors: yuchengpeng yuchengpeng.ex
 * @LastEditTime: 2022-11-21 18:49:27
 * @FilePath: \B2B\src\views\balanceQuery\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "@/utils/request";

export function getList(params) {
    return http({
        url: '/balance/queryWaitBalanceInfoList.nd',
        method: 'get',
        params
    })
}
// 根据商家查询所属组织 /comm/findOrgByCustomer.nd
export function getOrgByCode(params) {
    return http({
        url: '/comm/findOrgByCustomer.nd',
        method: 'post',
        params
    })
}
// 根据主账户查询子账户code
export function getCodeByParent(params) {
    return http({
        url: '/customer/getCustInfoByParentMdmCode.nd',
        method: 'post',
        params
    })
}

export function basicsData(params) {
    return http({
        url: '/balance/balanceInit.nd',
        method: 'get',
        params
    })
}
// 查询余额
export function baseMoney(params) {
    return http({
        url: '/balance/getMoney.nd',
        method: 'get',
        params
    })
}
// 查询余额-新
export function baseMoneyNew(data) {
    return http({
        url: '/balance/getMoneyList.nd',
        method: 'post',
        type: 'stringfy',
        contentType: "application/json;charset=UTF-8",
        data
    })
}

// 在线充值
export function saveMoney() {
    return http({
        url: '/cart/selfOrderPay.nd',
        method: 'get'
    })
}
// 充值记录

export function saveHisetory() {
    return http({
        url: '/cart/selfOrderQuery.nd',
        method: 'get'
    })
}