import {
    //基础数据
    basicsData,
    getList,
    // 查询余额
    baseMoney,
    // 查询余额new
    baseMoneyNew,
    // 充值
    saveMoney,
    // 充值记录
    saveHisetory,
    // 主账户查询子账户
    getCodeByParent,
    // 商家查物料组
    getOrgByCode
} from './api';
import { message } from 'ant-design-vue';
import {
    saveMoneyType
} from "@/common/constant";

export default {
    name: 'inspection',
    data() {
        return {
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/center/balanceQuery",
                    name: "balanceQuery",
                    title: "余额查询"
                }
            ],
            code: '',
            componany: '',
            // 预占用金额弹框
            modelShow: false,
            pageLoadFlagTable: true,
            // 查看占用明细列表
            columns: [{
                    label: '销售组织',
                    prop: 'orgName',
                    key: 'orgName',
                },
                {
                    label: '物料组',
                    prop: 'matklName',
                    key: 'amatklNamege'
                },
                {
                    label: '订单编码',
                    prop: 'orderCode',
                    key: 'orderCode'
                },
                {
                    label: '订单类型',
                    prop: 'orderType',
                    key: 'orderType'
                },
                {
                    label: '订单状态',
                    prop: 'status',
                    key: 'status'
                },
                {
                    label: '商品型号&数量',
                    prop: 'productAndQtys',
                    key: 'productAndQtys',
                },
                {
                    label: '付款状态',
                    prop: 'fkStatus',
                    key: 'fkStatus'
                }, {
                    label: '订单金额',
                    prop: 'orderAmt',
                    key: 'orderAmt'
                }, {
                    label: '预占用金额',
                    prop: 'waitBalance',
                    key: 'waitBalance',
                    slot:'businessFileCode'
                }, {
                    label: '要求到货周次',
                    prop: 'weekName',
                    key: 'weekName'
                },
                {
                    label: '下单日期',
                    prop: 'createdDate',
                    key: 'createdDate'
                },
            ], // 查看占用明细列表
            data: [],
            pager: {
                pageNo: 1,
                pageSize: 10,
                count: 10
            },
            orgList: [],
            matkls: [],
            searchaData: {
                customerCode: '',
                orgCode: '',
                matklCode: '',
                ids:['2603']
            },
            customerCodes:'',
            orgCodes:[],
            orgIds: [],
            orgCodesSelect: [],
            orgCodesName:[],
            matklCodes:[],
            orgMatklsSelect: [],
            matklCodesName:[],
            itemChose: [],
            choseIndex: 0,
            choseIndexWU: 0,
            showSpe: false,
            pageLoadFlag: false,
            pageLoadFlags: true,
            cunsNames: '',
            orgId: '',
            dataAll: [],
            saveMoneyValue: {},
            URLTO: '',
            shopAccountList:[],
            fullName:[],
            contentList:[],
            customerCisCode:'',
            matklAll:[],// 全部物料组
            cisCode:'',
            orgListNew:[],
            isShowYE: true, // 是否展示为零余额，默认传false
        }
    },
    mounted() {
        const {cisCode,customerCode,customerName} = this.$store.state.user.userInfo.customer
        const { account } = this.$store.state.user.userInfo.account
        this.searchaData.customerCode =customerCode;
        this.customerCodes =customerCode
        this.customerCisCode =customerCode
        this.componany =customerName;
        this.cunsNames = this.searchaData.customerCode + this.componany
        this.cisCode = account
        // 基础数据
        this.getBasicData();
        // 商家查物料组
        // this.getOrgByCode()
        // 查询子账户
        this.getCodeByParent()

        this.URLTO = saveMoneyType;
    },
    created(){
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
    },
    beforeDestroy() {
        document.querySelector('body').setAttribute('style', '')
    },
    methods: {
        // 商家查销售组织
        getOrgByCode(){
            getOrgByCode({customerCisCode:this.cisCode}).then(res=>{
                const { code,list,msg } = res.data
                if(code == 0 && list && list.length > 0) {
                    let arr = list.map((item)=>{
                        return {
                          id:item.ORG_ID,
                          organizationCode:item.ORG_CODE,
                          organizationName:item.ORG_NAME
                        }
                    })
                    let organizationCode = arr.map(el => el.organizationCode)
                    arr.unshift({id: '', organizationCode: '', organizationName: '全部'})
                    this.orgList = arr
                    this.orgCodesSelect = [this.orgList[1].organizationName]
                    this.orgIds = [this.orgList[1].id]
                    this.orgCodes = [this.orgList[1].organizationCode]
                    this.orgCodesName = [this.orgList[1].organizationName]
                    //物料组默认为全部
                    this.matklCodesName = ['全部']
                    this.matklCodes = ['']
                    this.orgMatklsSelect = ['全部']
                }else {
                    this.orgIds = []
                    this.orgList = []
                    this.orgCodes = []
                    this.orgCodesName = []
                }
            })
        },
        // 主账户查子账户
        getCodeByParent(){
            const mdmCode = this.$store.state.user.userInfo.customer.customerCode
            const mdmName = this.$store.state.user.userInfo.customer.customerName
            this.customerCodes = this.$store.state.user.userInfo.customer.customerCode
            
            getCodeByParent({mdmCode}).then(res => {
                const { code,list,msg } = res.data
                if(list.length > 0) {
                    list.reverse()
                    
                const codeS = list.map(item => item.mdmCode)
                const nameS = list.map(item => item.fullName)
                this.fullName = mdmCode + '-' + mdmName
                this.shopAccountList =list.map((item)=>{
                    return {
                        id:item.cisCode,
                        code: item.mdmCode == null ? '' : item.mdmCode,
                        name: item.fullName
                    }
                })
                }
            })
        },
        // 选择子账户商家
        selectShop(item,index){
            if(item.code){
                this.customerCodes = item.code
                this.customerCisCode = item.code
                this.cisCode = item.id
                this.getOrgByCode()
                // this.getCodeByParent() // 选择商家的时候查询子账户
            }
        },
        // 在线充值
        saveMoneyOnline() {
            saveMoney().then(res => {
                this.saveMoneyValue = res.data;
                this.$nextTick(() => {
                    var selfOrderPay = document.getElementById("selfOrderPay");
                    selfOrderPay.submit();
                })
            })
        },
        // 充值记录
        saveMoneyOnlineHis() {
            saveHisetory().then(res => {
                this.saveMoneyValue = res.data;
                this.$nextTick(() => {
                    var selfOrderPayh = document.getElementById("selfOrderPayH");
                    selfOrderPayh.submit();
                })
            })
        },
        pageSearch(pager) {
            this.pager.pageNo = pager.pageNo;
            this.pager.pageSize = pager.pageSize;
            var start = (pager.pageNo - 1) * this.pager.pageSize;
            this.data = this.dataAll.slice(start, start + this.pager.pageSize)
        },

        //基础数据
        getBasicData() {
            let params = {
                orgId: this.orgId
            }
            basicsData(params).then(res => {
                const { msg } = res.data
                if(res.data){
                    const { orgList,matkls} = res.data
                    this.pageLoadFlags = false;
                    this.orgList = orgList;
                    this.matkls = matkls;
                    if(orgList && orgList.length > 0 ){
                        this.searchaData.orgCode = orgList[0].organizationCode;
                        const orgCodesAll = orgList.map((item)=>{
                            return item.organizationCode
                        })
                        this.orgList.unshift({id: '', organizationCode:'',organizationName:'全部'})
                        // this.orgListNew.unshift({organizationCode:orgCodesAll,organizationName:'全部'})
                        this.orgCodesSelect = [this.orgList[1].organizationName]
                        this.orgIds = [this.orgList[1].id]
                        this.orgCodes = [this.orgList[1].organizationCode]
                        this.orgCodesName = [this.orgList[1].organizationName]
                    }
                    if(matkls && matkls.length > 0){
                        this.searchaData.matklCode = matkls[0].matklCode
                        const matklCodesAll = matkls.map((item)=>{
                            return item.matklCode
                        })
                        
                        this.matkls.unshift({matklCode:'',matklName:'全部'})
                        
                        this.orgMatklsSelect = ['全部']
                        this.matklCodes.push(this.matkls[0].matklCode)
                        this.matklCodesName.push(this.matkls[0].matklName)
                    }
                    this.$nextTick(()=>{
                        // 查询余额
                        this.searchMoney({
                            pageSize:20,
                            pageNo:1,
                            customerCode: this.customerCodes,
                            orgCodes:this.searchaData.orgCode,
                            matklCodes:this.searchaData.matklCode
                        })
                    })
                }else{
                    this.$message.warning(msg);
                }
            })
        },
        tablelist() {
            let data = this.ssearchList;
            getList(data).then(res => {
                this.tableData = res.data.list;
                this.pager.count = res.data.list.length;

            })
        },
        handleChangeOrg(value) {
            if(value.length <= 0) {
                this.orgIds = ['']
                this.orgCodes = ['']
                this.orgCodesName = ['全部']
                this.orgCodesSelect = ['全部']
            }else{
                if(value[value.length -1] == '全部') {
                    this.orgIds = ['']
                    this.orgCodes = ['']
                    this.orgCodesName = ['全部']
                    this.orgCodesSelect = ['全部'] 
                }else if(value[0] == '全部') {
                    this.orgCodesSelect = value.splice(0, 1) 
                }else {
                    this.orgCodesName = value
                    this.orgIds = this.orgList.reduce((acc, cur) => {
                        if(value.includes(cur.organizationName)){
                            acc.push(cur.id)
                        }
                        return acc
                    }, [])
                    this.orgCodes = this.orgList.reduce((acc, cur) => {
                        if(value.includes(cur.organizationName)){
                            acc.push(cur.organizationCode)
                        }
                        return acc
                    }, [])
                    this.orgCodesName = value
                    this.orgCodesSelect = value
                }
            }
            this.getMatklData()
        },
        handleBulrOrg() {
          this.getMatklData() 
        },
        async getMatklData() {
            let orgIds = []
            if(this.orgIds.indexOf('') > -1) {
                orgIds = this.orgList.map(el => el.id)
                const index = orgIds.findIndex(el => !el || el == '')
                orgIds.slice(index, 1)
            }else {
                orgIds = this.orgIds
            }
            let arr = await Promise.all(
                orgIds.map(el => {
                    return new Promise((resolve, reject) => {
                        basicsData({
                            orgId: el
                        }).then(res => {
                            const { matkls } = res.data
                            resolve({
                                matkls: matkls
                            })
                        })
                    })
                })
            )
            let matklsData = arr.reduce((acc, cur) => {
                acc = [...acc, ...cur.matkls]
                return acc
            }, [])
            // 去重
            let matklsDataArr = []
            matklsData.forEach(el => {
                if(matklsDataArr.findIndex(it => it.id == el.id) == -1) {
                    matklsDataArr.push(el)
                }
            })
            matklsDataArr.unshift({"matklCode": "", "matklName": "全部"})
            this.matklCodesName = ['全部']
            this.matklCodes = ['']
            this.orgMatklsSelect = ['全部']
            this.matkls = matklsDataArr.map((item)=>{
                return {
                    matklCode: item.matklCode,
                    matklName: item.matklName
                }
            })
        },
        // 选择销售组织
        orgChangeS(item){
            if(item.organizationCode == '') {
                this.orgIds = ['']
                this.orgCodes = ['']//item.organizationCode
                this.orgCodesName = ['全部']
            }else{
                
                const index = this.orgCodes.findIndex(el => el == '')
                if(index > -1) {
                    this.orgIds.splice(index, 1)
                    this.orgCodes.splice(index, 1)
                    this.orgCodesName.splice(index,1)
                }
                if(this.orgCodes.indexOf(item.organizationCode) > -1){
                    if(this.orgCodes.length <= 1) {
                        this.orgIds = ['']//this.orgList[0].organizationCode
                        this.orgCodes = ['']//this.orgList[0].organizationCode
                        this.orgCodesName = ['全部']
                    }else {
                        this.orgIds.splice(this.orgIds.indexOf(item.id),1)
                        this.orgCodes.splice(this.orgCodes.indexOf(item.organizationCode),1)
                        this.orgCodesName.splice(this.orgCodesName.indexOf(item.organizationName),1)
                    }
                }
                else{
                    this.orgIds.push(item.id)
                    this.orgCodes.push(item.organizationCode)
                    this.orgCodesName.push(item.organizationName)
                }
            }      
        },
        handleChangeMatkl(value) {
            // this.orgMatklsSelect = this.matklCodesName//this.matklCodes 
            if(value.length <= 0) {
                // this.orgIds = ['']
                this.matklCodes = ['']//item.organizationCode
                this.matklCodesName = ['全部']
                this.orgMatklsSelect = ['全部']
            }else{
                if(value[value.length -1] == '全部') {
                    // this.orgIds = ['']
                    this.matklCodes = ['']//item.organizationCode
                    this.matklCodesName = ['全部']
                    this.orgMatklsSelect = ['全部'] 
                }else if(value[0] == '全部') {
                    this.orgMatklsSelect = value.splice(1, value.length - 1) 
                }else {
                    this.matklCodes = this.matkls.reduce((acc, cur) => {
                        if(value.includes(cur.matklName)){
                            acc.push(cur.matklCode)
                        }
                        return acc
                    }, [])
                    this.matklCodesName = value
                    this.orgMatklsSelect = value
                }
            }
        },
        // 选择物料组
        makChangeS(item){
            if(item.matklCode == '') {
                this.matklCodes = ['']
                this.matklCodesName = ['全部']
            }else{
                const index = this.matklCodes.findIndex(el => el == '')
                if(index > -1) {
                    this.matklCodes.splice(index, 1)
                    this.matklCodesName.splice(index,1)
                }
                if(this.matklCodes.indexOf(item.matklCode) > -1){
                    if(this.matklCodes.length <= 1) {
                        this.matklCodes = ['']
                        this.matklCodesName = ['全部']
                    }else {
                        this.matklCodes.splice(this.matklCodes.indexOf(item.matklCode),1)
                        this.matklCodesName.splice(this.matklCodesName.indexOf(item.matklName),1)
                    }
                }
                else{
                    this.matklCodes.push(item.matklCode)
                    this.matklCodesName.push(item.matklName)
                }
            }
        },
        //物料组
        makChange(id, index, name) {
            this.searchaData.matklCode = id;
            if(id == ''){
                this.matkCodes == ''
                this.matklCodesName = '全部'
            }
            else if(id != this.orgCodes.indexOf(id) < -1){
                this.matklCodes.push(id)
                this.matklCodesName.push(name)
            }
            this.choseIndexWU = index;
            this.itemChose = []
        },
        // 销售组织
        orgChange(id, index, orgId, name) {
            this.searchaData.orgCode = id;
            this.orgCodes.push(id)
            this.orgCodesName.push(name)
            this.choseIndex = index;
            this.choseIndexWU = 0
            this.orgId = orgId;
            this.itemChose = []
            this.getBasicDataClick();
        },
        // 点击获取基础数据 销售组织和物料组列表
        getBasicDataClick() {
            let params = {
                orgId: this.orgId
            }
            basicsData(params).then(res => {
                if(res.data){
                    this.pageLoadFlags = false;
                    this.matkls = res.data.matkls;
                    this.orgList = res.data.orgList;
                    if(res.data.matkls && res.data.matkls.length > 0){
                        this.searchaData.matklCode = res.data.matkls[0].matklCode
                        // this.matklCodes[0] = res.data.matkls[0].matklCode
                    }
                }
            })
        },
        
        // 查询余额
        searchMoney() {
            this.pageLoadFlag = true;
            let orgCodes = [], matklCodes = []
            if(this.orgCodes.indexOf('') > -1) {
                orgCodes = this.orgList.map(el => el.id) // organizationCode
                const index = orgCodes.findIndex(el => el == '')
                orgCodes.splice(index, 1)
            }else {
                orgCodes = this.orgIds
            }

            if(this.matklCodes.indexOf('') > -1) {
                matklCodes = this.matkls.map(el => el.matklCode)
                const index = matklCodes.findIndex(el => el == '')
                matklCodes.splice(index, 1)
            }else {
                matklCodes = this.matklCodes
            }
            // let params = this.searchaData;
            let params = {
                pageSize:100,
                pageNo:1,
                customerCode: this.customerCodes,
                orgIds:orgCodes,
                matklCodes:matklCodes,
                showZero:!this.isShowYE
            }
            baseMoneyNew(params).then(res => {
                const {code, data,msg} = res.data
                if(code == 0){
                    if(data.content.length > 0){
                        data.content.forEach(el => {
                          el.canUseMoney = (el.balanceAccount - el.waitBalance).toFixed(2)
                        });
                    }
                    this.itemChose = data.content;
                    this.pageLoadFlag = false;
                    this.showSpe = true;
                    
                    // this.contentList = data.content
                }else{
                    this.pageLoadFlag = false;
                }
            })
        },
        // 预占用
        prentMoney(index,item) {
            this.modelShow = true;
            this.pager = {
                pageNo: 1,
                pageSize: 10,
                count: 10
            }
            var params = {
                orgCode: item.orgCode,
                matklCode: item.matklCode,
                pageNo:1,
                pageSize:10000,
            }
            getList(params).then(res => {
                this.pageLoadFlagTable = false;
                this.dataAll =  res.data.dataList.list;
                this.data =  res.data.dataList.list.slice(0, 10)
                this.pager.count = res.data.dataList.list.length;
                if(res.data.dataList.list &&res.data.dataList.list.length >0){
                    this.data = res.data.dataList.list.slice(0, 10);
                }
            })
        },
        // 预占用金额弹框
        handleOk(e) {
            this.modelShow = false;
        },
        // 分页
        pagesiezes(data) {
            // var prev = data - 1;
            var start = (data - 1) * this.pager.pageSize;
            this.data = this.dataAll.slice(start, start + this.pager.pageSize)
            this.pager.pageSize;
        },
    }
}